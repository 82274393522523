import React from "react";
import BusInfoCard from "./bus/BusInfoCard";
import { Col, Row } from "react-bootstrap";
// import BusApi from "../../../api/BusApi";
import busArrivalInfo from "../../../data/dummyBusArrival.json";

const BusArrived = () => {
  //   // 특정 정류소의 모든 버스 도착 정보
  //   BusApi.getBusArrivalList({
  //     bstopId: "165000111", // 필수: 정류소 ID
  //     numOfRows: 10, // 필수: 한 페이지 결과 수 (기본값 10)
  //     pageNo: 1, // 필수: 페이지 번호 (기본값 1)
  //   })
  //     .then((data) => console.log("버스 도착 정보 : ", data))
  //     .catch((error) => console.error(error));

  //   // 주변 정류소 목록 조회 (미추홀구문학경로당)
  //   BusApi.getBusStationAroundList({
  //     numOfRows: 10, // 필수: 한 페이지 결과 수 (기본값 10)
  //     pageNo: 1, // 필수: 페이지 번호 (기본값 1)
  //     LAT: 37.4378231, // 필수: 위도 좌표
  //     LNG: 126.6814188, // 필수: 경도 좌표
  //   })
  //     .then((data) => console.log("주변 버스 정류소 : ", data))
  //     .catch((error) => console.error(error));

  return (
    <Row className={"row-gap-2 p-3 h-100"}>
      {busArrivalInfo.map((busInfo, idx) => (
        <BusInfoCard key={idx} busInfo={busInfo} />
      ))}
    </Row>
  );
};

export default BusArrived;
