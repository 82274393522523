import React, { Fragment } from "react";
// import styles from "./BusInfoCard.module.css";
import "./BusInfoCard.scss";
import { Col, Row } from "react-bootstrap";
import BusYR from "../../../../assets/bus/bus-YR.svg";
import BusGY from "../../../../assets/bus/bus-GY.svg";

const BusInfoCard = ({ busInfo }) => {
  console.log("busInfo", busInfo);

  return (
    <Col className={`bus-info text-center d-flex flex-column gap-3`}>
      <Col className={`bus-title`}>
        <h4 className="fs-1 fw-bold">{busInfo.stationName}</h4>
      </Col>
      <Col className="bus-arrival d-flex justify-content-between">
        <Col xs={4} className="arrive-bg">
          <h4 className="fs-1 fw-bold">곧 도착</h4>
        </Col>
        <Col
          xs={8}
          className="d-flex justify-content-center align-items-center gap-3"
        >
          <img className="d-block" src={BusYR} alt="bus-img01" />
          <span className="fs-1 fw-bold">
            {busInfo.arrivalInfo[0].busNumber}
          </span>
        </Col>
      </Col>
      <Col className="bus-list w-100">
        <Col className="bus-list-title fs-3">
          <Row className="mx-3 px-4 py-2">
            <Col xs={4} className="border-end">
              버스 번호
            </Col>
            <Col xs={4} className="border-end">
              남은 시간
            </Col>
            <Col xs={4} className="">
              남은 정거장
            </Col>
          </Row>
        </Col>
        <Col className="bus-list-contents w-100">
          {busInfo.arrivalInfo.map((bus, idx) => (
            <Row
              key={idx}
              className="px-4 py-3 bus-list-content align-items-center mx-3 border-bottom"
            >
              <Col
                xs={4}
                className="bus-number border-end d-flex justify-content-center align-items-center gap-3"
              >
                <img className="" src={BusGY} alt="bus-img02" />
                <span
                  className="fs-3 fw-bold text-start"
                  style={{ width: "4rem" }}
                >
                  {bus.busNumber}
                </span>
              </Col>
              <Col xs={4} className="border-end">
                <span className="fs-4  fw-bold">
                  <b className="fs-1">{bus.remainingTime}</b>분 후
                </span>
              </Col>
              <Col xs={4}>
                <h4 className="fs-3 fw-bold">{bus.remainingStops}정류장</h4>
              </Col>
            </Row>
          ))}
        </Col>
      </Col>
    </Col>
  );
};

export default BusInfoCard;
