import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const Null = ({ isFullscreen }) => {
  console.log("fullllllll", isFullscreen);
  return (
    <Row
      className={"footer"}
      style={{
        height: "17vh",
        maxHeight: "18vh",
        position: "absolute", // 풀스크린일 때 고정
        bottom: isFullscreen ? "16px" : "auto", // 고정 위치 설정
        left: "50%", // 화면 중앙에 고정
        transform: "translateX(-50%)",
        zIndex: isFullscreen ? -1 : 1000,
        paddingRight: "2.5rem",
        paddingLeft: "3.8rem",
      }}
    >
      <Col
        xs={12}
        className="h-100 border-0 rounded-5 d-flex p-5"
        style={{
          backgroundColor: "#2C2C2C",
        }}
      ></Col>
    </Row>
  );
};

export default Null;
