import React from 'react'
import Webcam from "react-webcam";

function Capture() {

    // Webcam 장비 강제 인식으로 Captureboard 연결할 예정
//    const [deviceId, setDeviceId] = React.useState({});
    const [devices, setDevices] = React.useState([]);
  
    const handleDevices = React.useCallback(
      mediaDevices => {
        // captureboard 이름으로 필터링
        setDevices(mediaDevices.filter(({ label }) => label.includes("Live Gamer EXTREME 3 (")));
        //setDevices(mediaDevices.filter(({ label }) => label.includes("Live Streamer CAP")));
        console.log(mediaDevices);
      },
      [setDevices]
    );
  
    React.useEffect(
      () => {
        navigator.mediaDevices.enumerateDevices().then(handleDevices);
      },
      [handleDevices]
    );

    React.useEffect(
        () => {
            console.log(devices)
        },
        [devices]

    )

    return (
    // <>
    //   {devices.map((device, key) => (
    //       <div>
    //         <Webcam audio={false} videoConstraints={{ deviceId: device.deviceId }} />
    //         {device.label || `Device ${key + 1}`}
    //       </div>

    //     ))}
    // </>
        <>
        {
            devices.length === 0 ? <div>"No Signal"</div> :
             <Webcam audio={true} videoConstraints={{deviceId: devices[0]?.deviceId}}></Webcam>
        }
        </>  
    )
}

export default Capture
