import React from 'react';
import styles from './Card.module.css';

const CardDiv = ({children, size = 'large', className, isFullscreen}) => {
  const sizeClassMap = {
    large: 'card-lg',
    small: 'card-sm',
  };

  const sizeClass = sizeClassMap[size] || '';
  return (
      <div className={`${styles.card} ${styles[sizeClass]} ${className || ''}`}
           style={{height: (size === "large" && isFullscreen) ? "43.5vh" : "", alignContent: "center"}}
      >
        {children}
      </div>
  );
};

export default CardDiv;