// src/components/content/additional/weather/CurrentWeather.jsx
import React from "react";
// import styles from "./CurrentWeather.module.css";
import CardDiv from "./CardDiv";
import {getDate} from "../../../../util/util";
import {IoCalendarClearOutline, IoLocationOutline} from "react-icons/io5";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function CurrentWeather({currentWeather, city, isFullscreen}) {
  if (!currentWeather) {
    return <div>Loading...</div>;
  }

  const {
    weather,
    dt: dataUnix,
    sys: {country},
    main: {temp},
  } = currentWeather;

  const [{icon, description}] = weather;
  const iconUrl = require(`../../../../assets/images/${icon}.png`);

  return (
      <CardDiv size="large" className={""} isFullscreen={isFullscreen}>
        <Row className={"row-gap-3"}>
          <Col xs={12}>
            <h2 className={"styles.title fs-4 mb-0"}>현재</h2>
          </Col>
          <Col xs={12} className={""}>
            <Row>
              <Col xs={6} className={"align-middle my-auto"}>
                <span className={"styles.heading align-middle my-auto"} style={{fontSize: "5rem"}}>
                  {`${Math.round(temp)}°`}<sup>c</sup>
                </span>
              </Col>
              <Col xs={6} className={" align-middle my-auto"}>
                <img
                    src={iconUrl}
                    // width="80"
                    // height="80"
                    alt="clouds"
                    className={"styles.img  align-middle my-auto"}
                />
              </Col>
            </Row>
          </Col>

          <Col xs={12}>
            <span className={"styles.desc fs-"}>{description}</span>
          </Col>

          <hr/>

          <Col xs={12}>
            <Row className={"styles.list row-gap-3 mt-3"}>
              <Col xs={12} className={"styles.item align-middle my-auto"}>
            <span className={"styles.icon align-middle my-auto"}>
              <IoCalendarClearOutline size={20}/>
            </span>
                <span className={"styles.text ms-2 align-middle my-auto"}>
                  {getDate(dataUnix)}
                </span>
              </Col>
              <Col xs={12} className={"styles.item"}>
            <span className={"styles.icon "}>
              <IoLocationOutline size={20}/>
            </span>
                <span className={"styles.text ms-2 align-middle my-auto"}>{city}, {country}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardDiv>
  );
}