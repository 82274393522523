import React from 'react'
//import electron from 'electron'
//import fs from 'fs'
import ReactPlayer from 'react-player'

// <ReactPlayer url='https://www.youtube.com/watch?v=LXb3EKWsInQ' />
// <ReactPlayer url='file://e/Temp/test.mp4' />
// <ReactPlayer url='silver-media://e/Temp/test.mp4' />

function getVideoURL() {
    const strings = [
        "https://youtu.be/2jrauVcXM38?si=uhswqGTB9N6YYFHF", 
        "https://youtu.be/5QgfU0lFfy4?si=zVwfKSQ5qf01y_np", 
        "https://youtu.be/PS-t-BknGT4?si=8t4Rz6r-3nCFLkPV"];
    const randomIndex = Math.floor(Math.random() * strings.length);
    return strings[randomIndex];
}

function Media() {

    return (
        <>
        <ReactPlayer url={getVideoURL()}
        playing
        width={"100%"}
        height={"100%"}
        controls={false}
        />
        </>
    )
}

export default Media
