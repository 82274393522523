import React from 'react';
// import styles from './HourlyForecast.module.css';
import CardDiv from './CardDiv';
import {getHours} from '../../../../util/util';
import directionImg from '../../../../assets/images/direction.png';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function HourlyForecast({forecastData, isFullscreen}) {
  const filteredData = forecastData.list.filter((_, index) => index < 8);

  return (
      <CardDiv size="large" className={""} isFullscreen={isFullscreen}>
        {/*<h2 className={"styles.title fs-4 mb-2"}>시간대별 날씨</h2>*/}
        <Row className={"row-gap-4"}>
          <Col xs={12}>
            <h2 className={"styles.title fs-4 mb-0"}>시간대별 날씨</h2>
          </Col>
          <Col xs={12}>
            <Row className={"styles.list"}>
              {filteredData.map((forecast) => {
                const {
                  dt,
                  main: {temp},
                  weather,
                } = forecast;
                const [{icon, description}] = weather;
                const iconUrl = require(`../../../../assets/images/${icon}.png`);
                return (
                    <Col key={dt} className={"styles.item text-center"} style={{flex: "0 0 auto", width: "12.5%"}}>
                      <CardDiv size="small" className={"card-small"}>
                        <Row className={"row-gap-1"}>
                          <Col xs={12}>
                            <p className={"styles.text"}>{getHours(dt)}</p>
                          </Col>
                          <Col xs={12}>
                            <img
                                src={iconUrl}
                                width="40"
                                height="40"
                                alt={description}
                                className={"styles.img"}
                                title={description}
                            />
                          </Col>
                          <Col xs={12}>
                            <p className={"styles.text"}>{`${Math.round(temp)}°`}</p>
                          </Col>
                        </Row>

                      </CardDiv>
                    </Col>
                );
              })}
            </Row>
          </Col>
          <Col xs={12}>
            <Row className={"styles.list"}>
              {filteredData.map((forecast) => {
                const {
                  dt,
                  weather,
                  wind: {deg, speed},
                } = forecast;
                const [{description}] = weather;
                return (
                    <Col key={dt} className={"styles.item text-center"} style={{flex: "0 0 auto", width: "12.5%"}}>
                      <CardDiv size="small" className={""}>
                        <Row>
                          <Col xs={12}>
                            <p className={"styles.text"}>{getHours(dt)}</p>
                          </Col>
                          <Col xs={12}>
                            <img
                                src={directionImg}
                                width="40"
                                height="40"
                                alt={description}
                                className={"styles.img"}
                                title={description}
                                style={{transform: `rotate(${deg - 180}deg)`}}
                            />
                          </Col>
                          <Col xs={12}>
                            <p className={"styles.text"}>{Math.round(speed * 3600 / 1000)} km/h</p>
                          </Col>
                        </Row>
                      </CardDiv>
                    </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </CardDiv>
  );
}