import React, {useState, useEffect, CSSProperties} from 'react';
// import styles from "./MainContent.module.css";
import CurrentWeather from "./CurrentWeather";
import WeatherApi from "../../../../api/weatherApi";
import Forecast from "./Forecast";
import Highlights from "./Highlights";
import HourlyForecast from "./HourlyForecast";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ClipLoader from "react-spinners/ClipLoader";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  // borderColor: "red",
  position: "absolute",
  top: "40%",
  left: "45%",
  // transform: "translate(-50%, -50%)",  // transfrom 이벤트 이슈
};

export default function MainContent({isFullscreen}) {
  const [location, setLocation] = useState({lat: null, lon: null});
  const [currentWeather, setCurrentWeather] = useState(null);
  const [forecastData, setForecastData] = useState(null);
  const [city, setCity] = useState(null);
  const [airPollution, setAirPollution] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [color, setColor] = useState("#ffffff");

  useEffect(() => {
    // Fetch location data here or set it manually
    setLocation({lat: 37.454994, lon: 126.702999}); // Example: 인천 샤펠드미앙 coordinates
    // setLocation({lat: 37.5665, lon: 126.9780}); // Example: Seoul coordinates
  }, []);

  useEffect(() => {
    if (location.lat && location.lon) {
      setIsLoading(true);
      Promise.all([
        WeatherApi.getCurrentWeather(location.lat, location.lon),
        WeatherApi.getForecast(location.lat, location.lon),
        WeatherApi.getReverseGeo(location.lat, location.lon),
        WeatherApi.getAirPollution(location.lat, location.lon)
      ])
          .then(([currentWeatherData, forecastData, cityData, airPollutionData]) => {
            setCurrentWeather(currentWeatherData);
            setForecastData(forecastData);
            setCity(cityData);
            setAirPollution(airPollutionData);
            setIsLoading(false);
          })
          .catch(error => {
            console.error("Error fetching weather data:", error);
            setIsLoading(false);
          });
    }
  }, [location]);

  if (isLoading) {
    return <ClipLoader
        color={color}
        loading={isLoading}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
    />;
  }

  return (
      <Row className={"row-gap-2 p-3"}>
        <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} className={""}>
          <CurrentWeather currentWeather={currentWeather} city={city} isFullscreen={isFullscreen}/>
        </Col>
        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} className={""}>
          <Highlights airPollution={airPollution} currentWeather={currentWeather} isFullscreen={isFullscreen}/>
        </Col>
        <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
          <Forecast forecastData={forecastData} isFullscreen={isFullscreen}/>
        </Col>
        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} className={""}>
          <HourlyForecast forecastData={forecastData} isFullscreen={isFullscreen}/>
        </Col>
      </Row>
  );
}