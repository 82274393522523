import React from "react";
import MainContent from "./weather/MainContent";

function Weather({ isFullscreen }) {
  /*    return (
          <WeatherWidget
              provider='openWeather'
              apiKey='0664ff2cadd5abeb28a7fbaafa5accaf'
              location='Incheon'
              tempUnit="C"
              windSpeedUnit="kmph"
              lang="kr"
          />
      )*/

  return <MainContent isFullscreen={isFullscreen} />;
}

export default Weather;
