import HomeSVG from "../assets/menus/icon-home.svg";
import BroadCastActiveSVG from "../assets/menus/icon-broadcast_active.svg";
import MessageActiveSVG from "../assets/menus/icon-message_active.svg";
import AdditionalActiveSVG from "../assets/menus/icon-additional_active.svg";
import StreamingActiveSVG from "../assets/menus/icon-streaming_active.svg";
import MeetingActiveSVG from "../assets/menus/icon-meeting_active.svg";
import IptvActiveSVG from "../assets/menus/icon-iptv_active.svg";
import WeatherActiveSVG from "../assets/menus/icon-weather_active.svg";
import HomeActiveSVG from "../assets/menus/icon-home_active.svg";
import BroadCastSVG from "../assets/menus/icon-broadcast.svg";
import MessageSVG from "../assets/menus/icon-message.svg";
import AdditionalSVG from "../assets/menus/icon-additional.svg";
import StreamingSVG from "../assets/menus/icon-streaming.svg";
import MeetingSVG from "../assets/menus/icon-meeting.svg";
import IptvSVG from "../assets/menus/icon-iptv.svg";
import WeatherSVG from "../assets/menus/icon-weather.svg";
import BusSVG from "../assets/menus/icon-bus.svg";
import BusActiveSVG from "../assets/menus/icon-bus_active.svg";
import MessageListSVG from "../assets/menus/icon-message-list.svg";
import MessageListActiveSVG from "../assets/menus/icon-message-list_active.svg";
import VideoCallSVG from "../assets/menus/icon-video.svg";
import VideoCallActiveSVG from "../assets/menus/icon-video_active.svg";
// hooks/RenderIcon.js
// hooks/RenderIcon.js
export const renderIcon = (menuName, menu) => {
  console.log("menuName: " + menuName);
  console.log("menu: " + JSON.stringify(menu));

  if (!menu || !menu.name) return null; // Check if menu is defined

  if (menuName === menu.name) {
    switch (menu.name) {
      case "home":
        return HomeSVG;
      case "broadcast":
        return BroadCastActiveSVG;
      case "message":
        return MessageActiveSVG;
      case "messageList":
        return MessageListActiveSVG;
      case "videoCall":
        return VideoCallActiveSVG;
      case "additional":
        return AdditionalActiveSVG;
      case "streaming":
        return StreamingActiveSVG;
      case "meeting":
        return MeetingActiveSVG;
      case "iptv":
        return IptvActiveSVG;
      case "weather":
        return WeatherActiveSVG;
      case "busArrived":
        return BusActiveSVG;
      default:
        return HomeActiveSVG;
    }
  } else {
    switch (menu.name) {
      case "home":
        return HomeSVG;
      case "broadcast":
        return BroadCastSVG;
      case "message":
        return MessageSVG;
      case "messageList":
        return MessageListSVG;
      case "videoCall":
        return VideoCallSVG;
      case "additional":
        return AdditionalSVG;
      case "streaming":
        return StreamingSVG;
      case "meeting":
        return MeetingSVG;
      case "iptv":
        return IptvSVG;
      case "weather":
        return WeatherSVG;
      case "busArrived":
        return BusSVG;
      default:
        return HomeSVG;
    }
  }
};
