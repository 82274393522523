import React, {useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import Before from "../../../assets/broadcast/before.svg";
import {STATIC_BASE_URL} from "../../../config";

const SelectedMessage = ({selectedMessage, isFullscreen}) => {

  useEffect(() => {
    console.log(selectedMessage);
  }, [selectedMessage]);

  const formattedContent = selectedMessage?.content?.replace(/\r\n/g, "<br />");

  return (
      <div className="py-4 px-5">
        {/* Header Section */}
        <Row className="fs-3 text-center py-3 border-bottom custom-border-secondary">
          <Col
              xs={3}
              className="d-flex justify-content-between align-items-center border-end custom-border-secondary px-4"
          >
            <span className="fw-bold">어르신 성함</span>
            <span>{selectedMessage?.receiver}</span>
          </Col>
          <Col
              xs={3}
              className="d-flex justify-content-between align-items-center border-end custom-border-secondary px-4"
          >
            <span className="fw-bold">발신자 명</span>
            <span>{selectedMessage?.author}</span>
          </Col>
          <Col
              xs={6}
              className="d-flex justify-content-between align-items-center px-4"
          >
            <span className="fw-bold">수신일시</span>
            <span>
            {new Date(selectedMessage?.updated_at).toLocaleString("ko-KR", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}
          </span>
          </Col>
        </Row>

        {/* Title Section */}
        <Row
            className="fs-2 fw-bold py-4 px-4 "
            style={{
              borderBottom: "2px dashed #3c3c3c", // 점선 스타일
            }}
        >
          <span className="p-0">{selectedMessage?.title}</span>
        </Row>


        {/*{selectedMessage.attachments?.length > 0 && (
        <Row className="my-4 px-3 gap-3">
          {selectedMessage.attachments.map((attachment, index) => (
            <Col
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={index}
              className="d-flex justify-content-center align-items-center"
            >
              {attachment.type === "image" ? (
                <img
                  src={attachment.url}
                  alt={`Attachment ${index + 1}`}
                  className="img-fluid"
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                    maxHeight: "20vh",
                  }}
                />
              ) : attachment.type === "video" ? (
                <video
                  src={attachment.url}
                  controls
                  className="video-fluid"
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                    maxHeight: "20vh",
                  }}
                />
              ) : null}
            </Col>
          ))}
        </Row>
      )}*/}

        {/* Details Section */}
        <Row className="fs-4 mt-3 px-4 overflow-y-auto">
          <Col
              xs={12}
              sm={6}
              md={6}
              lg={4}
              className="justify-content-center align-items-center"
          >
            <img
                src={`${STATIC_BASE_URL}${selectedMessage.media}`}
                alt={`selectedMessage.media`}
                className="img-fluid"
                style={{
                  width: "100%",
                  height: "auto",
                }}
            />
          </Col>
          <Col xs={12}>
            <p
                className="fs-2 mt-4"
                dangerouslySetInnerHTML={{__html: formattedContent}}
            >
            </p>
          </Col>
        </Row>
      </div>
  );
};

export default SelectedMessage;
