import React, {useEffect, useState, useRef} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MainLayout from "../components/layout/MainLayout";
import Content from "../components/layout/Content";
import Sidebar from "../components/layout/Sidebar";
import Slide from "../components/layout/FooterContent/Slide";
import menuJSON from "../data/menus.json";
import {renderIcon} from "../hooks/RenderIcon";
import LogoSmSVG from "../assets/common/logo-sm.svg";
import LiveSVG from "../assets/common/live.svg";
import ComAlert from "../components/layout/FooterContent/ComAlert";
import Notice from "../components/layout/FooterContent/Notice";
import messagesList from "../data/dummyMessages.json";
import Null from "../components/layout/FooterContent/Null";
import {getJWT, getMessage, getNotice} from "../api/apiClient";
import MessagePopup from "../components/layout/popup/MessagePopup";

const Main = () => {
  const [menus, setMenus] = useState(menuJSON);
  const [activeMenu, setActiveMenu] = useState("home");
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showNotice, setShowNotice] = useState(false);
  const [noticeInfo, setNoticeInfo] = useState({});
  const [noticeCount, setNoticeCount] = useState(0);
  const [latestMessage, setLatestMessage] = useState(null);
  const [showMessagePopup, setShowMessagePopup] = useState(false);

  const prevActiveMenu = useRef(activeMenu);

  const handleMessageSelect = (message) => {
    setSelectedMessage(message);
    setIsFullscreen(true);
  };

  const handleBackToList = () => {
    setSelectedMessage(null);
  };

  useEffect(() => {
    if (!isFullscreen) {
      setActiveMenu(prevActiveMenu.current);
    }
  }, [isFullscreen]);

  const handleFullscreenToggle = () => {
    if (!isFullscreen) {
      prevActiveMenu.current = activeMenu;
    }
    setIsFullscreen((prev) => !prev);
  };

  useEffect(() => {
    prevActiveMenu.current = activeMenu;
  }, [activeMenu]);

  const renderTitle = (activeMenu) => {
    const menu =
        menus.find((m) => m.name === activeMenu) ||
        menus
            .flatMap((m) => m.submenus || [])
            .find((sm) => sm.name === activeMenu);
    if (!menu) return null;

    if (menu.name === "home") {
      return (
          <div className="d-flex align-items-center">
            <img src={LogoSmSVG} alt={""} className="me-3"/>
            <span className="title fs-1 my-auto align-middle">
            Smartsilver.Life
          </span>
          </div>
      );
    }

    if (menu.name === "meeting") {
      return (
          <div className="d-flex align-items-center">
            <img src={LiveSVG} alt={"LiveSVG"} className="me-3"/>
            <span className="title fs-1 my-auto align-middle">
            {menu.name_kor}
          </span>
          </div>
      );
    }

    return (
        <div className="d-flex align-items-center">
          <img
              src={renderIcon(activeMenu, menu)}
              alt={menu.name_kor}
              className="me-3"
          />
          <span className="title fs-1 my-auto align-middle">{menu.name_kor}</span>
        </div>
    );
  };

  const handleAlertCancle = () => {
    setShowAlert(false);
  };

  const handleMessageConfirm = () => {
    setShowAlert(false);
    setShowMessagePopup(true);
    // handleMessageSelect(messagesList[0]);
    // setIsFullscreen(true);
    // setActiveMenu("messageList");
  };

  const handleNoticeConfirm = () => {
    setShowNotice(false);
  };

  const getNoticeLatest = () => {
    getNotice(true)
        .then((res) => {
          // localStorage notice와 res id 비교
          const notice = JSON.parse(window.localStorage.getItem("notice"));
          if (notice && res.data[0].id !== notice.id) {
            setShowNotice(true);
            setNoticeInfo(res.data[0]);
            setNoticeCount(1);
            window.localStorage.setItem("notice", JSON.stringify(res.data[0]));
          } else if (!notice) {
            setShowNotice(true);
            setNoticeInfo(res.data[0]);
            setNoticeCount(1);
            window.localStorage.setItem("notice", JSON.stringify(res.data[0]));
          }
        })
        .catch((err) => {
          console.error(err);
        });
  };

  const getMessageLatest = () => {
    getMessage(true)
        .then((res) => {
          // console.log(res);
          // localStorage notice와 res id 비교
          const message = JSON.parse(window.localStorage.getItem("message"));
          if (message && res.data[0].id !== message.id) {
            setLatestMessage(res.data[0]);
            setShowAlert(true);
            window.localStorage.setItem("message", JSON.stringify(res.data[0]));
          } else if (!message) {
            setLatestMessage(res.data[0]);
            setShowAlert(true);
            window.localStorage.setItem("message", JSON.stringify(res.data[0]));
          }
        })
        .catch((err) => {
          console.error(err);
        });
  };

  useEffect(() => {
    setInterval(() => {
      getNoticeLatest();
      getMessageLatest();
    }, 10000);
  }, []);

  useEffect(() => {
    // console.log("noticeInfo:" + JSON.stringify(noticeInfo));
    // console.log(noticeCount);
    // repeat_time 처리 필요

    // 15초마다 repeat_time 체크
    if (noticeInfo.repeat_time && noticeCount <= noticeInfo.repeat_time) {
      if (noticeCount === noticeInfo.repeat_time) {
        setTimeout(() => {
          setShowNotice(false);
        }, 15000);
      } else {
        setTimeout(() => {
          setShowNotice(false);
        }, 14900);
        setTimeout(() => {
          setShowNotice(true);
          setNoticeCount(noticeCount + 1);
        }, 15000);
      }
    }
  }, [noticeCount, noticeInfo]);

  useEffect(() => {
    // console.log(showNotice);
  }, [showNotice]);

  const [token, setToken] = useState("");

  useEffect(() => {
    getJWT("샤펠드미앙")
        .then((res) => {
          console.log("token: ", res);
          console.log(res.data.token);
          setToken(res.data.token.slice(2, -1));
        })
        .catch((err) => {
          console.error(err);
        });
  }, []);


  return (
      <MainLayout>
        <Row className={"main row-gap-3"}>
          <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className={"px-5"}
          >
            <div className={"title my-auto align-middle"}>
              {renderTitle(activeMenu)}
            </div>
          </Col>
          {/* 메세지 팝업 */}
          {showMessagePopup && latestMessage ? (
              <MessagePopup
                  message={latestMessage}
                  onClose={() => setShowMessagePopup(false)}
              />
          ) : (
              <>
                <Col xs={12} className={"px-5"}>
                  <Row className={"justify-content-between"}>
                    <Col
                        className={`${isFullscreen ? "" : ""}`}
                        style={
                          isFullscreen
                              ? {flex: "0 0 auto", width: "100%"}
                              : {flex: "0 0 auto", width: "79.2%"}
                        }
                    >
                      <Content
                          activeMenu={activeMenu}
                          isFullscreen={isFullscreen}
                          setIsFullscreen={handleFullscreenToggle}
                          messagesList={messagesList}
                          selectedMessage={selectedMessage}
                          handleMessageSelect={handleMessageSelect}
                          handleBackToList={handleBackToList}
                          token={token}
                      />
                    </Col>
                    <Col
                        className={isFullscreen ? "d-none" : ""}
                        style={{
                          flex: "0 0 auto",
                          width: isFullscreen ? "0" : "19.5%",
                        }}
                    >
                      <Sidebar
                          menus={menus}
                          activeMenu={activeMenu}
                          setActiveMenu={setActiveMenu}
                          isFullscreen={isFullscreen}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    className={"px-5 w-100 h-100"}
                    style={{position: "relative"}}
                >
                  <>
                    {!isFullscreen && activeMenu === "home" ? (
                        <Slide/>
                    ) : (
                        <Null isFullscreen={isFullscreen}/>
                    )}

                    {showAlert && (
                        <ComAlert
                            type={"message"}
                            text={`${latestMessage?.author}님이 보낸 메시지가 도착하였습니다. 확인하시겠습니까?`}
                            onCancle={handleAlertCancle}
                            onConfirm={handleMessageConfirm}
                            isFullscreen={isFullscreen}
                        />
                    )}

                    {showNotice && (
                        <Notice
                            isFullscreen={isFullscreen}
                            onCancle={handleNoticeConfirm}
                            noticeInfo={noticeInfo}
                        />
                    )}
                  </>
                </Col>
              </>
          )}
        </Row>
      </MainLayout>
  );
};

export default Main;
