// import ScreenSel from './components/screen-selector/ScreenSel';
import {BrowserRouter, HashRouter, Route, Routes} from "react-router-dom";
import Main from "./pages/Main";
import NotFoundPage from "./pages/NotFoundPage";

// import MainLayout from './components/layout/MainLayout';
function App() {
  return (
      // test용 hardcoding
      // <ScreenSel></ScreenSel>
      <BrowserRouter>
        {/*<HashRouter>*/}
        {/*<MainLayout>*/}
        <Routes>
          <Route path="/" element={<Main/>}/>

          <Route path="/404" element={<NotFoundPage/>}/>
        </Routes>
        {/*</MainLayout>*/}
        {/*</HashRouter>*/}
      </BrowserRouter>
  );
}

export default App;
