import React from "react";
// import styles from "./Forecast.module.css";
import CardDiv from "./CardDiv";
import {monthNames, weekDayNames} from "../../../../util/util";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Forecast({forecastData, isFullscreen}) {
  const dailyForecast = [];

  const {city: {timezone}} = forecastData;

  const now = new Date();
  const today = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString().split("T")[0];

  forecastData.list.forEach((forecast) => {
    const {dt} = forecast;
    const newDate = new Date((dt + timezone) * 1000);
    const date = newDate.toISOString().split("T")[0];

    if (date === today) return;

    const existingDay = dailyForecast.find((item) => item.date === date);

    if (!existingDay) {
      dailyForecast.push({
        date,
        temp_min: forecast.main.temp_min,
        temp_max: forecast.main.temp_max,
        weather: forecast.weather[0],
      });
    } else {
      existingDay.temp_min = Math.min(existingDay.temp_min, forecast.main.temp_min);
      existingDay.temp_max = Math.max(existingDay.temp_max, forecast.main.temp_max);
    }
  });

  return (
      <CardDiv size="large" isFullscreen={isFullscreen}>
        {/*<h2 className={"styles.title fs-4 mb-2"}>날씨예보</h2>*/}
        <Row className={"row-gap-2"}>
          <Col xs={12}>
            <h2 className={"styles.title fs-4 mb-0"}>날씨예보</h2>
          </Col>
          <Col xs={12}>
            <Row className={"styles.list row-gap-3"}>
              {dailyForecast.map((forecast) => {
                const {temp_max, temp_min, weather, date} = forecast;
                const {icon, description} = weather;
                const forecastDate = new Date(date);
                const iconUrl = require(`../../../../assets/images/${icon}.png`);
                return (
                    <Col xs={12} key={date} className={"styles.item"}>
                      <Row className={""}>
                        <Col xs={6} className={"styles.wrapper"}>
                          <Row>
                            <Col xs={4} className={" my-auto align-middle"}>
                              <img
                                  src={iconUrl}
                                  width="42"
                                  height="42"
                                  alt={description}
                                  className={"styles.img"}
                                  title={description}
                              />
                            </Col>
                            <Col className={" my-auto align-middle"}>
                              <span className={"styles.max"}>{`${Math.round(temp_max)}°`} </span>
                              <span className={"styles.min fs-7 text-secondary"}>{`${Math.round(temp_min)}°`}</span>
                            </Col>
                          </Row>
                        </Col>
                        <Col className={"styles.label my-auto align-middle text-end text-secondary"}>
                          {monthNames[forecastDate.getUTCMonth()]} {forecastDate.getDate()}일
                        </Col>
                        <Col className={"styles.label my-auto align-middle text-end text-secondary"}>
                          {weekDayNames[forecastDate.getUTCDay()]}
                        </Col>
                      </Row>
                    </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </CardDiv>
  );
}