import React from "react";
import {Card, Col, Row} from "react-bootstrap";
import AlertSVG from "../../../assets/common/icon-alert.svg";

const ComAlert = ({
                    type,
                    text,
                    onCancle,
                    onConfirm,
                    cancleBtnText = "거절",
                    isFullscreen,
                  }) => {
  if (type === "message") {
    return (
        // 메세지 알람
        <Row
            className={"footer"}
            style={{
              height: "17vh",
              maxHeight: "18vh",
              position: isFullscreen ? "absolute" : "absolute", // 풀스크린일 때 고정
              bottom: isFullscreen ? "16px" : "auto", // 고정 위치 설정
              left: "50%", // 화면 중앙에 고정
              transform: "translateX(-50%)",
              zIndex: 1000,
              paddingRight: isFullscreen ? "3rem" : "2rem",
              paddingLeft: isFullscreen ? "3rem" : "3rem",
            }}
        >
          <Col xs={12} className={"h-100"}>
            <Card
                className={"border-0 rounded-5 h-100 p-5"}
                style={{backgroundColor: "#fff"}}
            >
              <Row className="h-100">
                <Col xs={1} className="text-center">
                  <img className="h-100" src={AlertSVG} alt="alert"/>
                </Col>
                <Col xs={7} className="">
                  <h3 className="text-black fw-bold fs-1 m-0">알림</h3>
                  <span className="text-black fs-2">{text}</span>
                </Col>
                <Col xs={4} className="d-flex gap-4">
                  <button
                      tabIndex={1}
                      className="w-100 btn btn-outline-secondary btn-lg text-black"
                      style={{borderColor: "#3c3c3c"}}
                      onClick={onCancle}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          onConfirm();
                        }
                      }}
                  >
                    닫기
                  </button>
                  <button
                      tabIndex={1}
                      className="w-100 btn btn-primary btn-lg"
                      onClick={onConfirm}
                  >
                    보기
                  </button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
    );
  } else if (type === "call") {
    return (
        // 영상통화 알람
        <Row className={"footer"}>
          <Col xs={12} className={"h-100"}>
            <Card
                className={"border-0 rounded-5 h-100 p-5"}
                style={{backgroundColor: "#fff"}}
            >
              <Row className="h-100">
                <Col xs={1} className="text-center">
                  <img className="h-100" src={AlertSVG} alt="alert"/>
                </Col>
                <Col xs={7} className="">
                  <h3 className="text-black fw-bold fs-1 m-0">알림</h3>
                  <span className="text-black fs-2">{text}</span>
                </Col>
                <Col xs={4} className="d-flex gap-4">
                  <button
                      className="w-100 btn btn-outline-secondary btn-lg text-black"
                      style={{borderColor: "#3c3c3c"}}
                      onClick={onCancle}
                  >
                    {cancleBtnText}
                  </button>
                  <button
                      className="w-100 btn btn-primary btn-lg"
                      onClick={onConfirm}
                  >
                    연결
                  </button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
    );
  } else {
    return null;
  }
};

export default ComAlert;
