import React, {useState} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Card} from "react-bootstrap";
import SlideImg from "../../../assets/slide/slide.png";
import LeftImg from "../../../assets/slide/left.svg";
import RightImg from "../../../assets/slide/right.svg";
import PairingImg01 from "../../../assets/pairing/pairing_1.png";
import PairingImg02 from "../../../assets/pairing/pairing_2.png";
import PairingImg03 from "../../../assets/pairing/pairing_3.png";
import liveSVG from "../../../assets/common/live.svg";
import slideList from "../../../data/EventSlidesList.json";

const Slide = () => {
  const [currentDateIndex, setCurrentDateIndex] = useState(0); // 해당 날짜의 인덱스
  const [visibleStartIndex, setVisibleStartIndex] = useState(0); // 슬라이드 시작 인덱스

  const handlePrevSlide = () => {
    if (visibleStartIndex > 0) {
      // 현재 날짜의 첫 번째 콘텐츠가 아니면 이전 콘텐츠로 이동
      setVisibleStartIndex((prev) => prev - 1);
    } else if (currentDateIndex > 0) {
      // 현재 날짜의 첫 번째 콘텐츠이고, 이전 날짜가 존재할 경우
      setCurrentDateIndex((prev) => prev - 1); // 이전 날짜로 이동
      const prevSlide = slideList.slides[currentDateIndex - 1]; // 이전 날짜의 슬라이드 가져오기
      setVisibleStartIndex(prevSlide.events.length - 3); // 이전 날짜의 마지막 3개 콘텐츠 설정(기본값)
    }
  };

  const handleNextSlide = () => {
    const currentSlide = slideList.slides[currentDateIndex]; // 현재 날짜의 슬라이드 데이터
    if (visibleStartIndex + 3 < currentSlide.events.length) {
      // 현재 날짜에 추가로 보여줄 콘텐츠가 있을 경우
      setVisibleStartIndex((prev) => prev + 1);
    } else if (currentDateIndex < slideList.slides.length - 1) {
      // 현재 날짜의 마지막 콘텐츠이고, 다음 날짜가 존재할 경우
      setCurrentDateIndex((prev) => prev + 1); // 다음 날짜로 이동
      setVisibleStartIndex(0); // 다음 날짜의 첫 번째 3개 콘텐츠 표시
    }
  };

  const currentSlide = slideList.slides[currentDateIndex];

  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };

  return (
      <Row className={"footer"}
           style={{
             height: "17vh",
             maxHeight: "18vh",
             position: "absolute", // 풀스크린일 때 고정
             bottom: "auto", // 고정 위치 설정
             left: "50%", // 화면 중앙에 고정
             transform: "translateX(-50%)",
             zIndex: 1000,
             paddingRight: "2rem",
             paddingLeft: "3rem",
           }}
      >
        <Col xs={12} className={"w-100 h-100"}>
          {/*<img src={SlideImg} alt={"slide"} className={"img-fluid w-100 h-100"}/>*/}
          <Card
              className={"display border-0 rounded-5 h-100"}
              style={{
                backgroundColor: "#2C2C2C",
              }}
          >
            {/*<Card.Img src={SlideImg} alt={"slide"} className={"img-fluid w-100 h-100"}/>*/}
            <Row className={"justify-content-center h-100"}>
              {/* 왼쪽 버튼 */}
              <Col
                  className={"text-center my-auto"}
                  style={{flex: "0 0 auto", width: "10%"}}
              >
                <img
                    src={LeftImg}
                    alt={"slide"}
                    className={"w-75 cursor-hover"}
                    tabIndex={0}
                    onClick={handlePrevSlide}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        handlePrevSlide();
                      }
                    }}
                />
              </Col>
              {/* 날짜 표시 */}
              <Col
                  className={
                    "d-flex flex-column justify-content-center align-items-center text-center my-auto border-end custom-border-secondary me-4"
                  }
                  style={{
                    flex: "0 0 auto",
                    width: "12%",
                    height: "80%",
                  }}
              >
                <h3 className={"fw-normal"}>{currentSlide.date}</h3>
                <h3 className={"fw-normal"}>{currentSlide.day}</h3>
              </Col>
              {/* 이벤트 슬라이드 */}
              <Col
                  className="my-auto"
                  style={{
                    overflow: "hidden", // 슬라이드가 부모 영역을 벗어나지 않도록 설정
                    width: "66%", // 슬라이더 부모 컨테이너 너비 고정
                  }}
              >
                <Row
                    className="justify-content-between align-items-center"
                    style={{
                      display: "grid", // Grid 레이아웃 사용
                      gridTemplateColumns: `repeat(${currentSlide.events.length}, 33.33%)`, // 각 콘텐츠의 너비를 1/3로 고정
                      transition: "transform 0.5s ease", // 부드러운 애니메이션 설정
                      transform: `translateX(-${visibleStartIndex * 33.33}%)`, // 현재 슬라이드 위치개수 * 개별 너비
                    }}
                >
                  {currentSlide.events.map((event, idx) => (
                      <Col
                          key={idx}
                          className="my-auto"
                          style={{
                            width: "100%", // Grid 내부 콘텐츠의 너비 고정
                            height: "100%", // Grid 내부 콘텐츠의 높이 고정
                            boxSizing: "border-box",
                          }}
                      >
                        <Card className={"border-0 rounded-5"}>
                          <img
                              src={require(`../../../assets/pairing/${event.image}`)}
                              alt={event.title}
                              className={"img-fluid w-100 h-100"}
                          />
                        </Card>
                        <div className={"mt-2"}>
                      <span
                          className={"fs-4 align-middle my-auto me-2 fw-bold"}
                      >
                        {event.time}
                      </span>
                          {event.isLive && (
                              <img
                                  src={liveSVG}
                                  alt={"LIVE"}
                                  className={"mx-2"}
                                  style={{minWidth: "2.5rem"}}
                              />
                          )}
                          <span className={"fs-4 align-middle my-auto"}>
                        {event.title}
                      </span>
                        </div>
                      </Col>
                  ))}
                </Row>
              </Col>
              {/* 오른쪽 버튼 */}
              <Col
                  className={"text-center my-auto"}
                  style={{flex: "0 0 auto", width: "10%"}}
              >
                <img
                    src={RightImg}
                    alt={"slide"}
                    className={"w-75 cursor-hover"}
                    tabIndex={0}
                    onClick={handleNextSlide}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        handleNextSlide();
                      }
                    }}
                />
              </Col>
            </Row>
            {/* 슬라이더 */}
            {/* <Slider {...settings}>
            {slideList?.slides.map((slide, idx) => (
              <div key={idx} className="d-flex">
                <Col className={"text-center my-auto"}>
                  <h3 className={"fw-normal"}>{slide.date}</h3>
                  <h3 className={"fw-normal"}>{slide.day}</h3>
                </Col>
                {slide.events.map((event, idx) => (
                  <Col
                    className={"my-auto"}
                    style={{ flex: "0 0 auto", width: "22%" }}
                  >
                    <Card className={"border-0 rounded-5"}>
                      <img
                        src={PairingImg01}
                        alt={"slide"}
                        className={"img-fluid w-100 h-100"}
                      />
                    </Card>
                    <div className={""}>
                      <span className={"fs-4 align-middle my-auto me-2"}>
                        13:00
                      </span>
                      <img src={liveSVG} alt={"LIVE"} className={"mx-1"} />
                      <span className={"fs-4 align-middle my-auto"}>
                        노래교실
                      </span>
                    </div>
                  </Col>
                ))}
              </div>
            ))}
          </Slider> */}
          </Card>
        </Col>
      </Row>
  );
};

export default Slide;
