import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Notice = ({isFullscreen, onCancle, noticeInfo}) => {
  return (
      <Row
          className={"footer notice mx-auto w-100"}
          style={{
            height: "17vh",
            maxHeight: "18vh",
            position: isFullscreen ? "absolute" : "absolute", // 풀스크린일 때 고정
            bottom: isFullscreen ? "16px" : "auto", // 고정 위치 설정
            left: "50%", // 화면 중앙에 고정
            transform: "translateX(-50%)",
            zIndex: 1000,
            paddingRight: isFullscreen ? "3rem" : "3rem",
            paddingLeft: isFullscreen ? "3rem" : "3rem",
          }}
      >
        <Col
            xs={12}
            className="h-100 border-0 rounded-5 d-flex p-5"
            style={{
              backgroundColor: "#2C2C2C",
              boxShadow: isFullscreen ? "0 0 8px rgba(23, 23, 23, 0.8)" : "",
            }}
        >
          <Col
              xs={1}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              xxl={1}
              className={
                "my-auto align-middle text-center p-0 border-end custom-border-secondary"
              }
          >
          <span className="fs-2 fw-bold">
            공지
            <br/>
            사항
          </span>
          </Col>
          <Col
              xs={9}
              sm={9}
              md={9}
              lg={9}
              xl={9}
              xxl={9}
              className={"fs-3 my-auto align-middle text-start px-5"}
          >
            {/*[인천광역시 일자리사업본부] 시니어 클럽 지원 및 노인일자리사업
            안전교육을 안내드립니다.*/}
            {noticeInfo.content}
          </Col>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} onClick={onCancle}>
            <button
                tabIndex={1}
                className="btn btn-secondary w-100 h-100">
              닫기
            </button>
          </Col>
        </Col>
      </Row>
  );
};

export default Notice;
