import {SWAGGER_BASE_URL} from "../config";
import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
// axios.defaults.withCredentials = true;

const axiosClient = axios.create({
  baseURL: SWAGGER_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    // 'Access-Control-Allow-Origin': '*', // CORS 허용
    // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS' // CORS 허용
  },
});

export const axiosClientFormData = axios.create({
  baseURL: `${SWAGGER_BASE_URL}`,
  timeout: 10000,
});

const axiosClientPostFormData = (url, formData) => {
  let ret;
  ret = axiosClientFormData.post(url, formData, {
    headers: {
      // "Content-Type": "multipart/form-data",
      "Content-Type": "application/json",
      // "Authorization": `Bearer ${token}`,
    },
  });
  return ret;
};

// 메인 - 하단 공지사항 알림
export const getNotice = (latest = true) => {
  try {
    return axiosClient.get(`/notice/?latest=${latest}`);
  } catch (e) {
    console.error(e);
  }
};

// 메인 - 하단 메시지 수신 알림
export const getMessage = (latest = true) => {
  try {
    return axiosClient.get(`/message/?latest=${latest}`);
  } catch (e) {
    console.error(e);
  }
};

export const messageList = () => {
  try {
    return axiosClient.get(`/message/`);
  } catch (e) {
    console.error(e);
  }
};

export const getJWT = (user) => {
  try {
    return axiosClient.post(`jitsi/token/`, {
      user: user,
    });
  } catch (e) {
    console.error(e);
  }
}