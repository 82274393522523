import React from 'react';
import MainLayout from "../components/layout/MainLayout";

const NotFoundPage = () => {

  //

  return (
      <MainLayout>
        return <h1>404 - Page Not Found</h1>
      </MainLayout>
  );
};

export default NotFoundPage;
