import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import SelectedMessage from "../../content/message/SelectedMessage";
import CloseSvg from "../../../assets/common/icon-close.svg";

const MessagePopup = ({ message, onClose }) => {
  return (
    <div
      style={{
        backgroundColor: "#2C2C2C",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "90%",
        height: "80vh",
        borderRadius: "12px",
        zIndex: 100000,
      }}
    >
      <SelectedMessage selectedMessage={message} />
      <img
        src={CloseSvg}
        alt="close"
        className={`position-absolute z-3 end-0 bottom-0 cursor-hover m-4 p-2`}
        tabIndex={0}
        onClick={onClose}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onClose();
          }
        }}
        style={{
          backgroundColor: "#171717",
          width: "50px",
          height: "50px",
          borderRadius: "50%",
          cursor: "pointer",
        }}
      />
    </div>
  );
};

export default MessagePopup;
