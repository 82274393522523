import React, { useEffect } from "react";
import thumbnail from "../../assets/main/thumbnail.jpg";
// import Home from '../content/main/Home'; // Adjust the import path as needed
import Conf_screen from "../content/conf/conf_screen";
import Media from "../content/additional/media";
import Capture from "../content/additional/capture";
import Weather from "../content/additional/weather";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import ScaleExpandingSVG from "../../assets/broadcast/scale-expanding.svg";
import ScaleReductionSVG from "../../assets/broadcast/scale-reduction.svg";
import Before from "../../assets/broadcast/before.svg";
import BusArrived from "../content/additional/BusArrived";
import MessageList from "../content/message/MessageList";
import VideoCall from "../content/message/VideoCall";

const Content = ({
  activeMenu = "home",
  isFullscreen,
  setIsFullscreen,
  messagesList,
  selectedMessage,
  handleMessageSelect,
  handleBackToList,
  token,
}) => {
  useEffect(() => {
    console.log("Content set activeMenu: " + activeMenu);
  }, [activeMenu]);

  useEffect(() => {
    console.log("token: ", token);
  }, [token])

  const Home = () => {
    return (
      <Row className={"item card border-0 h-100"}>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          className={"item card border-0 h-100"}
        >
          <img
            src={thumbnail}
            alt={"thumbnail"}
            className={"img-fluid h-100"}
            style={{ objectFit: "cover" }}
          />
        </Col>
      </Row>
    );
  };

  const renderContent = (activeMenu) => {
    if (activeMenu === "home") {
      return <Home />;
    } else if (activeMenu === "streaming") {
      console.log("token: ", token);
      return <Conf_screen token={token} />;
    } else if (activeMenu === "meeting") {
      return <Media />;
    } else if (activeMenu === "iptv") {
      return <Capture />;
    } else if (activeMenu === "messageList") {
      return (
        <MessageList
          isFullscreen={isFullscreen}
          selectedMessage={selectedMessage}
          messagesList={messagesList}
          handleMessageSelect={handleMessageSelect}
        />
      );
    } else if (activeMenu === "videoCall") {
      console.log("token: ", token);
      return <VideoCall 
      isFullscreen={isFullscreen} 
      token={token}
      />;
    } else if (activeMenu === "weather") {
      return <Weather isFullscreen={isFullscreen} />;
    } else if (activeMenu === "busArrived") {
      return <BusArrived isFullscreen={isFullscreen} />;
    }
  };

  return (
    <div
      className={`content position-relative ${
        isFullscreen ? "fullscreen" : ""
      }`}
    >
      <Row className={"row-gap-2 h-100"}>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          className={"h-100"}
        >
          <Card className={"display border-0 rounded-5 h-100"}>
            {renderContent(activeMenu)}
            {selectedMessage && (
              <div className={"scale"}>
                {/* <button
                  className={
                    "position-absolute return-btn z-3 end-0 bottom-0 cursor-hover btn btn-primary"
                  }
                  tabIndex={0}
                  onClick={() => handleBackToList()}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleBackToList();
                    }
                  }}
                >
                  돌아가기
                </button> */}
                <img
                  src={Before}
                  alt="before"
                  className={`position-absolute return-icon z-3 end-0 bottom-0 cursor-hover ${
                    isFullscreen ? "full" : ""
                  }`}
                  tabIndex={0}
                  onClick={() => handleBackToList()}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleBackToList();
                    }
                  }}
                />
              </div>
            )}

            <div
              className={"scale"}
              // tabIndex={0}
              // onClick={() => {
              //   if (selectedMessage) {
              //     // 메시지 상세 화면에서는 목록으로 돌아가기
              //     // handleBackToList();
              //   } else {
              //     // 다른 경우에는 풀스크린 상태만 토글
              //     setIsFullscreen(!isFullscreen);
              //   }
              // }}
            >
              <img
                tabIndex={0}
                src={isFullscreen ? ScaleReductionSVG : ScaleExpandingSVG}
                onClick={() => setIsFullscreen(!isFullscreen)}
                /*onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    setIsFullscreen(!isFullscreen);
                  }
                }}*/
                alt={"scale"}
                className={
                  "position-absolute scale-icon z-3 end-0 bottom-0 cursor-hover"
                }
                // style={{width: "100px", height: "100px", opacity: "0.5"}}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Content;
